@import "minima";

// schemer: https://coolors.co/c77640-c4d6b0-477998-291f1e-2d1e2f

// $siteBgColor: #27272a;           // original
$siteBgColor: #f1f4f9;
$siteHighlightColor: #c77640;     // copper
$siteHighlightHover: #dd9988;

$siteSecondaryColor: #477998;      // CG blue
$siteTertiaryColor: #C4D6B0;     // tea green
$siteQuaternaryColor: #2D1E2F;    // dark purple

$textColor: #17171B;
$textInverseColor: #f1f4f9;

//color: $siteHighlightColor; # orange
// color: #5C7880; # medium blue
// color: #819EA3; # light blue

html {
	scroll-behavior: smooth;
}

body {
  background-color: $siteBgColor;
  color: $textColor;
  font-size: 1.2rem !important;
}

h1, h2, h3, h4, h5, h6 {
  scroll-margin-top: 2em;
  font-weight: 500;
}

h1 {
  font-size: 2.3rem !important;
}

h2 {
  font-size: 2.1rem !important;
}

h3 {
  font-size: 1.9rem !important;
}

h4 {
  font-size: 1.7em !important;
}

h5 {
  font-size: 1.5rem !important;
}

h6 {
  font-size: 1.3rem !important;
}

.highlight-description {
  font-size: 1.2em;
}

#highlights {
  background-color:transparent;
  tr {
      background-color:transparent;
      &:hover {
        color: $siteHighlightHover;
        text-decoration: none;
        background-color: #ffffff;
    }
  }
  td {
    font-size: 1.2rem;
    padding: 0;
    a {
      padding: .5rem;
      display: block;
    }
  }
 }

div.framer {
  width: 100%;
  height: 0;
  padding-bottom: 56%; /* Change this till it fits the dimensions of your video */
  position: relative;
}

div.framer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.site-header {
  border-top: 5px solid $siteHighlightColor;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: $siteSecondaryColor;
  z-index: 1;
  .site-title {
    color: $textInverseColor !important;
  }
  .page-link {
    color: $textInverseColor !important;
  }
}

.site-nav {
  background-color: $siteSecondaryColor !important;
}

.site-footer {
  background-color: $siteSecondaryColor;
}

.footer-heading {
  color: $siteSecondaryColor !important;
}

#footer {
  color: $siteBgColor;
  a {
    color: $siteTertiaryColor !important;
  }
}

hr {
  margin: 1em 0 1em 0;
}

.post-date-header {
  font-size: 24px;
}

.post-list-heading {
  font-size: 24px;
}

.highlight-heading {
  font-size: 20px;
}

.post-date {
  float: right;
  font-size: 20px;
}

h3.post-link {
  font-size: 24px;
  display: inline-block;
  margin-right: 1em;
}

.page-content {
  padding-top: 0px;
}

.wrapper {
  max-width: -webkit-calc(1000px - (30px * 2));
  max-width: calc(1000px - (30px * 2));
}

div.pagenav {
  margin: 0 auto 1em auto;
  width: fit-content;
  div {
    display: inline-block;
  }
  .separator {
    margin-left: .3em; margin-right: .3em;
  }
  @media screen and (max-width: 600px) {
    width: auto;
    white-space: nowrap;
    margin: 0;
    div {
      margin: 0 0 .3em 0;
      padding: 0px;
      display: block;
    }
    .separator {
      margin: 0px;
      visibility: hidden;
      display: none;
    }
  }
}

p {
  margin-block-end: 1.5em;
}

blockquote {
  margin-block-end: 1.5em;
  font-size: 1.4rem;
  color: $siteSecondaryColor;
}

table {
  border-collapse: collapse;
  border: 0px;
  tr, th {
    background-color: transparent;
    color: $siteHighlightColor;
    border: 0px;
  }
  td {
    background-color: transparent;
    color: #999999;
    border: 0px;
  }
}

.advertisement {
  margin-top: 1em;
  margin-bottom: 1em;
}

.post-meta {
  font-size: 14px;
  color: #a0a0a0;
}

#banner {
  letter-spacing: 1px;
  font-size: 30px;
  color: #DDDDDD;
  margin: 1em 0 1em 0;
  text-align: center;
  div  {
    display: inline-block;
    white-space: nowrap;
  }
}

.small-banner {
  letter-spacing: 1px;
  font-size: 30px;
  color: #DDDDDD;
  margin: 2em 0 1em 0;
  text-align: center;
  div  {
    display: inline-block;
    white-space: nowrap;
  }
}

#intro {
  display: block;
  margin: 2em 0 2em 0;
}

@media screen and (max-width: 900px) {
  #intro {
    font-size: .8em;
  }
}

ul.entries {
  list-style: none;
  li {
    margin-bottom: .5em;
    div.entry-date {
      display: inline-block;
      width: 12em;
    }
    div.entry-text {
      display: inline-block;
      width: 30em;
    }
  }
}

@media screen and (max-width: 600px) {
  .site-nav {
    position: absolute;
    top: 9px;
    right: 15px;
    background-color: $siteBgColor;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    text-align: right;
  }

  #banner {
    font-size: 20px;
  }

  .post-date {
    font-size: 20px;
    margin-right: 1em;
    display: inline-block;
    float: none;
  }

  ul.entries {
    li {
      div.entry-date {
        display: block;
        width: auto;
      }
      div.entry-text {
        display: block;
        width: auto;
      }
    }
  }
}

/**
 * Icons
 */
 .svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #828282;
  padding-right: 5px;
  vertical-align: text-top;
}

/**
 * Icons
 */
 .svg-icon-large {
  display: inline;
  fill: #828282;
  margin: auto;
}

#contact-table {
  border: 0px;
  border-collapse: collapse;
  width: 280px;
  margin: auto;
}

#contact-table tr {
  background-color: transparent;
}

#contact-table td {
  color: #DDDDDD;
  font-size: .8em;
  background-color: transparent;
  text-align: left;
  white-space: nowrap;
  padding: 0;
  a div {
    width: 280px;
    height: 74px;
  }
  svg {
    float: left;
    margin-right: 20px;
    width: 4em;
    height: 4em;
  }
  a {
    text-decoration: none !important;
    display: block;
    height:100%;
    width:100%;
    color: #DDDDDD;
  }
  a:hover {
    color: $siteHighlightColor;
    background-color: #ffffff11;
    display: block;
    height:100%;
    width:100%;
    position:relative;
  }
}

img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

div.social {
  a {
    color: #828282;
    &:hover {
      color: $siteHighlightColor;
    }
    i {
      font-size: 1.5em;
      margin-right: 0.1em;
      margin-bottom: 0.3em;
    }
  }
  margin-bottom: 1em;
}

.site-title, .site-title:visited {
  color: $textColor;
  letter-spacing: .5px;
  &:hover {
    text-decoration: none;
  }
}

.site-nav .page-link {
  letter-spacing: .5px;
  color: $textColor;
  text-decoration: none;
  &:visited {
    color: $textColor;
    text-decoration: none;
  }
  &:hover {
    color: $siteHighlightHover;
    text-decoration: underline;
  }
}

a {
  color: $siteHighlightColor;
  text-decoration: none;
  &:visited {
    color: $siteHighlightColor;
    text-decoration: none;
  }
  &:hover {
    color: $siteHighlightHover;
    text-decoration: underline;
  }
}

div.env {
  margin: 1em 0 1em 0;
  div.env-entry {
    font-family: monospace;
  }
  div.env-key {
    display: inline-block;
    font-style: italic;
  }
  div.env-value {
    display: inline-block;
  }
}

.footnote {
  margin-left: 1em;
  margin-bottom: .8em;
}

.footnoteref {
  vertical-align: super;
  font-size: .8em;
}

a.anchor {
  position: relative;
  top: -80px;
}

.quote {
  font-style: italic;
  font-size: 1.2em;
  color: $siteHighlightHover;
  border: 1px #f7f7f7 solid;
  border-radius: 5px;

  float: right;
  width: 35%;
  margin: .5em 1em 1em 2em;
  padding: 1em;

  @media screen and (max-width: 800px) {
    float: none;
    width: auto;
    margin: .5em 1.5em 1em 1em;
    padding: .6em;
  }
}

table.stripe {
  a {
    display: inline-block;
    color: black;
    background-color: #cccccc;
    border: 2px solid #999999;
    padding: 0.4rem;
    min-width: 5rem;
    text-align: center;

    &:hover {
      color: $siteHighlightHover;
      text-decoration: none;
      background-color: #ffffff;
      border: 2px solid $siteHighlightHover;
    }
  }
}

.post-navigation {
  text-align: center;
  font-size: 1.2em;
  margin: 1em auto;

  .row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.flexgrid {

  margin: 1em auto;

  .row {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   }

  .column {
    margin: 1em auto;
    a:hover {
      text-decoration: none;
    }
    div {
      font-size: 1.2em;
      display: flex;
      justify-content: center;
      i {
        font-size: 2em;
        margin: .2em auto;
      }
    }
  }
}

.img-solution-design {
  margin: 0 auto;
  display: block;
  height: 75%;
  width: 75%;
}

#search-wrapper {
  max-width: -webkit-calc(1000px - (30px * 2));
  max-width: calc(1000px - (30px * 2));
  margin: 1.5rem 0 0 0;
  background-color: white;
  li {
    background-color: white;
  }
}

#search-input {
  font-size: 1rem;
  box-sizing: border-box;
  height: 2.5rem;
  border-width: 0px;
  background-image: url(/assets/search_icon.webp);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 40px;
  width: 100%;
  &:focus {
    outline: 0px;
    border: 1px $siteSecondaryColor solid;
    }
}

#results-container {
  box-sizing: border-box;
  display: block;
  z-index: 10;
  position: absolute;
  margin: 0px auto;
  height: 0px;
  width: 94%;
  font-size: 1rem;
  a {
    color: $siteSecondaryColor;
    text-decoration: none;
    &:hover {
      color: $siteHighlightHover;
      text-decoration: none;
    }
  }
  li {
    font-size: 1rem;
    list-style:none;
    padding: 0rem 1rem;
  }
}

pre.song {
  font-family: 'Lucida Console', 'Courier New', Courier, monospace;
  padding: .5rem;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #ffffff;
  white-space: pre-wrap;
  word-wrap: break-word;
}
